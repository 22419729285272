import { Dashboard } from '@mui/icons-material';
import {
  USERS,
  APPROVEUSERS,
  REFRESHFORM,
  DASHBOARDLIST,
  WITHDRAWALS,
  APPROVEDWITHDRAWALS,
  WITHDRAWREQUEST,
} from '../Constants';

const initialState = {
  usersList: null as any,
  dashboardList: null as any,
  withdrawList: null as any,
  approvewithdrawList: null as any,
  errors: null,
  loading: false,
  dashboardLoading: false,
  approval: false,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case USERS.PENDING:
      return { ...state, errors: null, loading: true };
    case USERS.SUCCESS:
      return {
        ...state,
        usersList: action.payload,
        loading: false,
      };
    case USERS.FAILURE:
      return { ...state, errors: action.payload, loading: false };

    case APPROVEUSERS.PENDING:
      return { ...state, errors: null, loading: true };

    case REFRESHFORM:
      return { ...state, errors: null, message: null, approval: false, loading: false };

    case APPROVEUSERS.SUCCESS:
      let temp = action.payload;
      const tempUsers = state.usersList.map((item: any) => {
        if (item.id === temp.id) item.status = temp.status;
        return item;
      });
      return {
        ...state,
        usersList: tempUsers,
        loading: false,
        message: '',
        errors: '',
        approval: true,
      };

    case DASHBOARDLIST.SUCCESS:
      return {
        ...state,
        dashboardList: action.payload.body.data[0],
        dashboardLoading: false,
        message: '',
        errors: '',
      };
    case DASHBOARDLIST.PENDING:
      return {
        ...state,
        // dashboardList: {},
        dashboardLoading: true,
        message: '',
        errors: '',
      };
    case APPROVEUSERS.FAILURE:
      return { ...state, errors: action.payload.error, loading: false, approval: false };

    case APPROVEDWITHDRAWALS.SUCCESS:
      let agentwithdrawals: any = Object.entries(action.payload.body.agentwithdrawals).map((value: any) => {
        const originalObject = value[1];
        const formattedDate = new Date(originalObject.createdAt).toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        });

        return {
          sno: parseInt(value[0]) + 1,
          ...originalObject,
          date: formattedDate,
        };
      });
      return {
        ...state,
        approvewithdrawList: agentwithdrawals,
        loading: false,
      };

    default:
      return { ...state };
  }
};

export default reducer;
