import { DEPOSITS, APPROVED_DEPOSITS, WITHDRAWALS, WITHDRAWREQUEST, APPROVED_WITHDRAWS } from '../Constants';

const initialState = {

  depositsList: null,
  withdrawList: null as any,
  errors: null,
  loading: false,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case DEPOSITS.PENDING:
      return { ...state, errors: null, loading: true };
    case DEPOSITS.SUCCESS:
      return {
        ...state,
        depositsList: action.payload.body,
        loading: false,
      };
    case DEPOSITS.FAILURE:
      return { ...state, errors: action.payload, loading: false };

    case APPROVED_DEPOSITS.PENDING:
      return { ...state, errors: null, loading: true };
    case APPROVED_DEPOSITS.SUCCESS:
      const list: any = state.depositsList;
      let updatedList = list.map((item: any) => {
        if (item.id === action.payload.id) {
          item.status = action.payload.status;
        }
        return item;
      });
      return {
        ...state,
        depositsList: updatedList,
        loading: false,
      };
    case APPROVED_DEPOSITS.FAILURE:
      return { ...state, errors: action.payload, loading: false };

    case WITHDRAWALS.SUCCESS:
      let mywithdrawals: any = Object.entries(action.payload.body.mywithdrawals).map((value: any) => {
        const originalObject = value[1];
        const formattedDate = new Date(originalObject.createdAt).toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric'
        });

        return {
          sno: parseInt(value[0]) + 1,
          ...originalObject,
          date: formattedDate
        };
      });
      return {
        ...state,
        withdrawList: mywithdrawals,
        loading: false,
      };

    case WITHDRAWREQUEST.PENDING:
      return { ...state, errors: null, loading: true };

    case WITHDRAWREQUEST.SUCCESS:
      let temp = action.payload;
      const updatedWithdrawList = [temp, ...state.withdrawList];
      return {
        ...state,
        withdrawList: updatedWithdrawList,
        loading: false,
        message: '',
        errors: '',
        approval: true
      };

    case WITHDRAWREQUEST.FAILURE:
      return { ...state, errors: action.payload.error, loading: false, approval: false };

    case APPROVED_WITHDRAWS.PENDING:
      return { ...state, errors: null, loading: true };

    case APPROVED_WITHDRAWS.SUCCESS:
      return {
        ...state,
        withdrawList: action.payload,
        loading: false,
        message: '',
        errors: '',
        approval: true
      };

    case APPROVED_WITHDRAWS.FAILURE:
      return { ...state, errors: action.payload.error, loading: false, approval: false };


    default:
      return { ...state };
  }
};

export default reducer;
