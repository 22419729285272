import { BETS } from '../Constants';

const initialState = {
  betsList: null,
  errors: null,
  loading: false,
  betsTotal: null,
  payoutTotal: null,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case BETS.PENDING:
      return { ...state, errors: null, loading: true };
    case BETS.SUCCESS:
      let reportsSummary: any = Object.entries(action.payload.body).map((value: any) => {
        const originalObject = value[1];
        const formattedDate = formatDate(originalObject.bet_time);

        return {
          id: parseInt(value[0]) + 1,
          ...originalObject,
          date: formattedDate,
        };
      });

      return {
        ...state,
        betsList: reportsSummary,
        betsTotal: getBetTotal(reportsSummary),
        payoutTotal: getPayoutTotal(reportsSummary),
        loading: false,
      };
    // return {
    //   ...state,
    //   betsList: action.payload.body,
    //   loading: false,
    // };
    case BETS.FAILURE:
      return { ...state, errors: action.payload, loading: false };

    default:
      return { ...state };
  }
};

function getBetTotal(records: any) {
  return records.reduce(
    (total: number, { bet_amount }: { bet_amount: any }) => total + (parseFloat(bet_amount) || 0),
    0
  );
}
function getPayoutTotal(records: any) {
  return records.reduce(
    (total: number, { payout_amount }: { payout_amount: any }) => total + (parseFloat(payout_amount) || 0),
    0
  );
}
function formatDate(val: any) {
  const utcTime = new Date(val);

  const year = utcTime.getUTCFullYear();
  const month = String(utcTime.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
  const day = String(utcTime.getUTCDate()).padStart(2, '0');
  const hours = String(utcTime.getUTCHours()).padStart(2, '0');
  const minutes = String(utcTime.getUTCMinutes()).padStart(2, '0');
  const seconds = String(utcTime.getUTCSeconds()).padStart(2, '0');

  const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  return formattedDateTime;
}

export default reducer;
