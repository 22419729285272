import { FunctionComponent } from 'react';
import { CircularProgress, CircularProgressProps, LinearProgress, Stack, StackProps } from '@mui/material';
import { APP_LOADING_COLOR, APP_LOADING_SIZE, APP_LOADING_TYPE } from '../config';

interface Props extends StackProps {
  color?: CircularProgressProps['color'];
  size?: number | string;
  type?: 'circular' | 'linear';
  value?: number;
}

/**
 * Renders MI circular progress centered inside Stack
 * @component AppLoading
 * @prop {string} [size] - size of the progress component. Numbers means pixels, string can be '2.5rem'
 */
const AppLoading: FunctionComponent<Props> = ({
  color = APP_LOADING_COLOR,
  size = APP_LOADING_SIZE,
  type = APP_LOADING_TYPE,
  value,
  ...restOfProps
}) => {
  const alignItems = type === 'linear' ? undefined : 'center';
  return (
    <Stack
      sx={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        left: '0px',
        top: '0px',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(49,62,89,.5)',
        zIndex: '9999',
      }}
      className="333"
      my={2}
      alignItems={alignItems}
      {...restOfProps}
    >
      <Stack sx={{ background: '#fff', padding: '10px', borderRadius: '10px' }}>
        {type === 'linear' ? (
          <LinearProgress color={color} value={value} />
        ) : (
          <CircularProgress color={color} size={size} value={value} />
        )}
      </Stack>
    </Stack>
  );
};

export default AppLoading;
